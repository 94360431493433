<template lang="pug">
div
  template(v-if="showSimpleViewedPageRule")
    div
    .d-flex
      .flex-grow-0.mr-4
        label.settings-label.mt-2 {{ $t('pageUrl') }}:
      .flex-grow-1
        .row.mb-3(v-for="(expression, expressionIndex) in currentRule.options.expression")
          om-select.col-md-4.col-12.pr-0.pb-3(
            :id="`urlOperators-${expressionIndex}`"
            size="small"
            :options="createOptionArray(stringOperators)"
            @input="setStringOperator($event, expressionIndex)"
            :value="{ key: currentRule.options.expression[expressionIndex].operator, value: $t(`${currentRule.options.expression[expressionIndex].operator}`) }"
          )
          om-input.col.pb-3(
            :id="`urlValue-${expressionIndex}`"
            small
            v-model.trim="currentRule.options.expression[expressionIndex].value"
            :error="$v.currentRule.options.expression.$each[expressionIndex].value.$error"
            :prefix="domainPrefix(expressionIndex)"
          )
          .brand-link.settings-label(
            v-if="currentRule.options.expression.length > 1"
            @click="removeExpression('expression', expressionIndex)"
          )
            fa-icon(variant="fa-trash" size="1.5")
          .brand-link.settings-label.px-4(v-else)
    span.brand-link.settings-label(
      @click="addExpression('expression', { operator: 'equals', value: '' })"
    )
      fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
  template(v-else)
    template(v-for="(expressionGroup, expressionGroupIndex) in expressionGroups")
      .expression-group-container.mb-4(
        :class="`expression-group-container-${expressionGroupIndex}`"
      )
        template(v-for="(expression, expressionIndex) in expressionGroup")
          .expression-container.mb-4(:class="`expression-container-${expressionIndex}`")
            .d-flex
              .flex-grow-0
                label.settings-label.mt-2.short-label(v-if="expressionIndex === 0") {{ $t('onAll') }}
              .flex-grow-1
                .d-flex.align-items-center.mb-3
                  om-select.w-10.mr-2.wide-select(
                    v-if="expressionIndex === 0"
                    :id="`pageType-${expressionIndex}`"
                    :options="createOptionArrayForPageType(pageTypes)"
                    size="small"
                    :value="{ key: expressionGroups[expressionGroupIndex][expressionIndex].pageType, value: $t(`frontendRules.viewedPage.${expressionGroups[expressionGroupIndex][expressionIndex].pageType}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }) }"
                    @input="setPageType($event, expressionGroupIndex, expressionIndex)"
                  )
                  .brand-link.settings-label.d-flex.flex-grow-1.justify-content-end
                    .brand-link.settings-label.text-center.mr-auto.ml-3(
                      v-if="expressionIndex === expressionGroups[expressionGroupIndex].length - 1 && expressionGroups[expressionGroupIndex].length === 1"
                      @click="newAndExpression(expressionGroupIndex)"
                    )
                      fa-icon(
                        variant="fa-plus"
                        :html="`&nbsp;${$t('frontendRules.viewedPage.addWhere')}`"
                      )
                    .brand-link.settings-label.text-center.mr-3(
                      v-if="showAddNew(expressionIndex, expressionGroupIndex)"
                      @click="newAndExpression(expressionGroupIndex)"
                    )
                      fa-icon(size="1.5" variant="fa-plus-circle")
                    .text-right.settings-label.delete-operator(
                      v-if="deleteIconVisibility(expressionGroups[expressionGroupIndex], expressionIndex)"
                      @click="removeExpressionOrGroup(expressionGroupIndex, expressionIndex)"
                    )
                      fa-icon(variant="fa-trash" size="1.5")
            .d-flex(v-if="hasExpressionWhereConditions(expressionGroupIndex, expressionIndex)")
              .flex-grow-0
                label.settings-label.mt-2.short-label {{ $t('where') + (expressionGroups[expressionGroupIndex][expressionIndex].pageType === 'page' ? ' URL' : '') }}
              .flex-grow-1
                .row.mb-3.align-items-center
                  om-select.col-md-4.col-12.pr-0(
                    :id="`productOperand-${expressionIndex}`"
                    v-if="expressionGroups[expressionGroupIndex][expressionIndex].pageType !== 'page'"
                    size="small"
                    :value="{ key: expressionGroups[expressionGroupIndex][expressionIndex].operand, value: $t(`frontendRules.viewedPage.operands.${expressionGroups[expressionGroupIndex][expressionIndex].operand}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }) }"
                    @input="setOperand($event, expressionGroupIndex, expressionIndex)"
                    :options="createOptionArrayForOperands(operandsOfCurrentPageType(expressionGroupIndex, expressionIndex))"
                  )
                  span.mr-4.ml-3(
                    v-if="['specificCategory', 'productCategory'].includes(expressionGroups[expressionGroupIndex][expressionIndex].operand)"
                  ) {{ $t('in') }}
                  om-select.col-12.pr-0(
                    v-else-if="expressionGroups[expressionGroupIndex][expressionIndex].operand === 'productAvailability'"
                    :class="getLocale === 'hu' ? 'col-md-3' : 'col-md-2'"
                    :id="`urlOperators-${expressionIndex}`"
                    size="small"
                    :value="{ key: expressionGroups[expressionGroupIndex][expressionIndex].operator, value: $t(expressionGroups[expressionGroupIndex][expressionIndex].operator) }"
                    @input="setStringOperatorForSrSf($event, expressionGroupIndex, expressionIndex)"
                    :options="createOptionArray(operatorsOfCurrentPageType(expressionGroupIndex, expressionIndex))"
                  )
                  om-select.col-md-4.col-12.pr-0(
                    v-else
                    :id="`urlOperators-${expressionIndex}`"
                    size="small"
                    :value="{ key: expressionGroups[expressionGroupIndex][expressionIndex].operator, value: $t(expressionGroups[expressionGroupIndex][expressionIndex].operator) }"
                    @input="setStringOperatorForSrSf($event, expressionGroupIndex, expressionIndex)"
                    :options="createOptionArray(operatorsOfCurrentPageType(expressionGroupIndex, expressionIndex))"
                  )
                  template(
                    v-if="['specificCategory', 'productCategory'].includes(expressionGroups[expressionGroupIndex][expressionIndex].operand)"
                  )
                    .col
                      om-select(
                        multiple
                        searchable
                        :options="categories"
                        optionText="key"
                        optionKey="value"
                        :id="`categories-${expressionIndex}`"
                        @input="setCategories($event, expressionGroupIndex, expressionIndex)"
                        :value.sync="expressionGroups[expressionGroupIndex][expressionIndex].value"
                        :placeholder="$t('select')"
                        size="small"
                      )
                  template(
                    v-else-if="expressionGroups[expressionGroupIndex][expressionIndex].operand === 'productAvailability'"
                  )
                    span.pl-2.settings-label {{ $t('available') }}
                  template(v-else)
                    om-input.col(
                      :id="`urlValue-${expressionIndex}`"
                      small
                      :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[expressionIndex].value.$error"
                      v-model.trim="expressionGroups[expressionGroupIndex][expressionIndex].value"
                      :prefix="expressionGroups[expressionGroupIndex][expressionIndex].pageType === 'page' && needStartingSlash(expressionGroups[expressionGroupIndex][expressionIndex].operator) ? domainWithDash : null"
                    )
                .font-size-0--75.font-weight-bold.mx-2.flex-grow-1(
                  v-if="expressionIndex !== expressionGroups[expressionGroupIndex].length - 1"
                ) {{ $t('andOperation') }}
              .brand-link.settings-label
                .text-right(
                  v-if="deleteIconVisibilityIfHasWhere(expressionIndex, expressionGroupIndex)"
                  @click="removeWhere(expressionGroupIndex, expressionIndex)"
                )
                  fa-icon(variant="fa-trash" size="1.5")
        .d-flex.align-items-center.mb-3(
          v-if="expressionGroupIndex !== currentRule.options.expressions.length - 1"
        )
          .font-size-0--75.font-weight-bold.mr-2 {{ $t('orOperation') }}
          .brand-separator
    span.brand-link.settings-label(
      @click="addExpressionGroup('expressions', { pageType: 'page', operator: 'equals', value: '' })"
    )
      fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import _setWith from 'lodash.setwith';
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import urlCampaignSettings from '@/mixins/urlCampaignSettings';
  import { getCollections } from '@/services/jfAdapter';
  import { mapGetters } from 'vuex';
  import embeddedV3 from '@/mixins/embeddedV3';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import dynamicContent from '@/mixins/dynamicContent';
  import { JETFABRIC_EMBEDDED_AND_DC } from '@/utils/features';

  export default {
    mixins: [
      settingsValidation,
      expressionOperations,
      urlCampaignSettings,
      embeddedV3,
      dynamicContent,
      frontendRuleUtils,
    ],
    props: {
      campaign: {
        type: Object,
      },
    },
    data: () => ({
      pageTypes: ['page', 'category', 'product'], // 'cart', 'checkout'
      stringOperators: [
        'equals',
        'notEquals',
        'contains',
        'notContains',
        'startsWith',
        'notStartsWith',
        'endsWith',
        'notEndsWith',
      ],
      numberOperators: ['equals', 'lessThan', 'lessThanEquals', 'greaterThan', 'greaterThanEquals'],
      booleanOperators: ['is', 'isNot'],
      operands: {
        page: [],
        category: [
          'categoryName',
          // 'categoryDescription',
          // 'categoryHandle',
          // 'categoryCreationDate',
          'numberOfProductsInCategory',
          'specificCategory',
        ],
        product: [
          'productName',
          // 'productDescription',
          'productCategory',
          'productPrice',
          'productType',
          'productVendor',
          'productAvailability',
          'productTag',
        ],
      },
      categories: [],
    }),
    computed: {
      ...mapGetters([
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'shopSettingsByDomain',
        'databaseId',
        'getLocale',
        'hasAccountFeature',
      ]),
      expressionGroups() {
        return this.currentRule.options.expressions;
      },
      showSimpleViewedPageRule() {
        const platformSpecific =
          this.isActiveShopifyDomain(this.domain) || this.isActiveShoprenterDomain(this.domain);
        const isNewCampaignTypes = this.isEmbeddedV3 || this.isDynamicContent;
        const hasJFEmbeddedFlag = this.hasAccountFeature(JETFABRIC_EMBEDDED_AND_DC);

        return (
          platformSpecific === false ||
          (platformSpecific && isNewCampaignTypes && hasJFEmbeddedFlag === false)
        );
      },
    },
    mounted() {
      this.fetchCategories();
      this.tryToMigrate();
    },
    methods: {
      tryToMigrate() {
        const needToMigrate =
          !this.showSimpleViewedPageRule && !!this.currentRule.options.expression;
        if (needToMigrate === false) return;

        const oldFormat = this.currentRule.options.expression;
        const expressionsArr = [];

        oldFormat.forEach((expression, index) => {
          _setWith(
            expressionsArr,
            index,
            [
              {
                ...expression,
                pageType: 'page',
              },
            ],
            (value, key, obj) => {
              this.$set(obj, key, value);
            },
          );
        });
        const newRule = _setWith({}, 'expressions', expressionsArr);
        this.$set(this.currentRule, 'options', newRule);
      },
      showAddNew(expressionIndex, expressionGroupIndex) {
        return (
          expressionIndex === this.expressionGroups[expressionGroupIndex].length - 1 &&
          this.expressionGroups[expressionGroupIndex].length !== 1
        );
      },
      deleteIconVisibilityIfHasWhere(expressionIndex, expressionGroupIndex) {
        return (
          this.hasExpressionWhereConditions(expressionGroupIndex, expressionIndex) &&
          this.expressionGroups[expressionGroupIndex].length === 1 &&
          this.expressionGroups[expressionGroupIndex][expressionIndex].pageType !== 'page'
        );
      },
      deleteIconVisibility(ruleGroup, expressionIndex) {
        if (expressionIndex === 0 && ruleGroup.length !== 1) {
          return false;
        }
        return true;
      },
      setStringOperator(event, expressionIndex) {
        this.currentRule.options.expression[expressionIndex].operator = event.key;
      },
      setPageType(event, expressionGroupIndex, expressionIndex) {
        this.expressionGroups[expressionGroupIndex][expressionIndex].pageType = event.key;
        this.onPageTypeChange(event, expressionGroupIndex, expressionIndex);
      },
      setStringOperatorForSrSf(event, expressionGroupIndex, expressionIndex) {
        this.expressionGroups[expressionGroupIndex][expressionIndex].operator = event.key;
      },
      setCategories(event, expressionGroupIndex, expressionIndex) {
        this.expressionGroups[expressionGroupIndex][expressionIndex].value = event;
      },
      setOperand(event, expressionGroupIndex, expressionIndex) {
        this.expressionGroups[expressionGroupIndex][expressionIndex].operand = event.key;
        this.onOperandChange(event, expressionGroupIndex, expressionIndex);
      },
      createOptionArrayForPageType(optionKeys) {
        return optionKeys.map((element) => ({
          key: element,
          value: this.$t(`frontendRules.viewedPage.${element}`, {
            category: this.isActiveShopifyDomain(this.domain) ? 'collection' : 'category',
          }),
        }));
      },
      createOptionArrayForOperands(optionKeys) {
        return optionKeys.map((element) => ({
          key: element,
          value: this.$t(`frontendRules.viewedPage.operands.${element}`, {
            category: this.isActiveShopifyDomain(this.domain) ? 'collection' : 'category',
          }),
        }));
      },
      newAndExpression(groupIndex) {
        const pageType = this.expressionGroups[groupIndex][0].pageType;
        this.addExpressionToGroup('expressions', groupIndex, {
          pageType,
          operand: this.operandsOfCurrentPageType(groupIndex, 0)[0],
          operator: 'equals',
          value: '',
        });
      },
      hasExpressionWhereConditions(groupIndex, expressionIndex) {
        const expression = this.expressionGroups[groupIndex][expressionIndex];
        if (expression.operand || expression.pageType === 'page') return true;
        return false;
      },
      removeWhere(groupIndex, expressionIndex) {
        this.expressionGroups[groupIndex][expressionIndex].operator = null;
        this.expressionGroups[groupIndex][expressionIndex].operand = null;
        this.expressionGroups[groupIndex][expressionIndex].value = null;
      },
      removeExpressionOrGroup(groupIndex, expressionIndex) {
        const expression = this.expressionGroups[groupIndex][expressionIndex];
        if (!expression.operand && expression.pageType !== 'page') {
          this.removeExpressionGroup('expressions', groupIndex);
        } else {
          this.removeExpressionFromGroup('expressions', groupIndex, expressionIndex);
        }
      },
      operandsOfCurrentPageType(groupIndex, index) {
        let operands = this.operands[this.expressionGroups[groupIndex][index].pageType];
        if (this.isActiveShoprenterDomain(this.domain))
          operands = operands.filter((operand) => operand !== 'productType');
        return operands;
      },
      operatorsOfCurrentPageType(groupIndex, index) {
        if (
          ['numberOfProductsInCategory', 'productPrice'].includes(
            this.expressionGroups[groupIndex][index].operand,
          )
        )
          return this.numberOperators;
        if (['productAvailability'].includes(this.expressionGroups[groupIndex][index].operand))
          return this.booleanOperators;
        return this.stringOperators;
      },
      // eslint-disable-next-line
      onPageTypeChange(event, groupIndex, index) {
        const expression = this.expressionGroups[groupIndex][index];
        this.$nextTick(() => {
          this.expressionGroups[groupIndex].forEach((item, index) => {
            if (index === 0) {
              item.pageType = expression.pageType;
              item.operand = null; // this.operands[value][0]
              item.operator = expression.pageType === 'page' ? this.stringOperators[0] : null;
              item.value = '';
            }
          });
          this.expressionGroups[groupIndex].splice(1);
        });
      },
      onOperandChange(event, groupIndex, index) {
        const expression = this.expressionGroups[groupIndex][index];
        this.$nextTick(() => {
          if (event.key === 'specificCategory') {
            expression.operator = 'equals';
            expression.value = null;
          } else if (event.key === 'productAvailability') {
            expression.operator = 'is';
            expression.value = 'available';
          } else {
            expression.operator = this.stringOperators[0];
            expression.value = '';
          }
        });
      },
      async fetchCategories() {
        const shop = this.shopSettingsByDomain(this.domain);

        if (shop) {
          try {
            const shopId = shop.type === 'shoprenter' ? shop.shopname : shop.myshopify_domain;
            const collections = await getCollections(this.databaseId, shop.type, shopId);

            if (collections) {
              const collectionIdMap = new Map(
                collections.map((collection) => [collection.collectionId, collection]),
              );

              const normalizeName = (collection) => {
                const parentId = collection.parent && collection.parent.collectionId;
                if (parentId && parentId !== collection.collectionId) {
                  const parent = collectionIdMap.get(parentId);

                  if (parent) {
                    return `${normalizeName(parent)} > ${collection.title}`;
                  }
                }

                return collection.title;
              };

              this.categories = collections.map((collection) => ({
                key: normalizeName(collection),
                value: `${collection.collectionId}`,
              }));
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          this.categories = [];
        }
      },
    },
    validations: {
      currentRule: {
        options: {
          expression: {
            $each: {
              value: {
                isCool(v, { operator }) {
                  return this.needStartingSlash(operator) || v.length;
                },
              },
            },
          },
          expressions: {
            $each: {
              $each: {
                value: {
                  isCool(v, { operator, pageType }) {
                    return pageType !== 'page' || this.needStartingSlash(operator) || v.length;
                  },
                },
              },
            },
          },
        },
      },
    },
  };
</script>

<style lang="sass">
  .expression-container:not(:first-child)
    margin-top: -2.5rem
</style>
