<template lang="pug">
.d-flex.align-items-center
  label.settings-label(for="timeBasedSession") {{ $t('durationOfTheVisit') }}:
  om-input#timeBasedSession.w-4.mx-3(
    type="number"
    :min="0"
    v-model.number="currentRule.options.value"
    :error="$v.currentRule.options.value.$error"
  )
  .settings-label {{ $tc('second', currentRule.options.value) }}
</template>

<script>
  import { required, minValue, integer } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';

  export default {
    mixins: [settingsValidation],

    validations: {
      currentRule: {
        options: {
          value: {
            required,
            minValue: minValue(0),
            integer,
          },
        },
      },
    },
  };
</script>
