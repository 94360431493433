<template lang="pug">
.row.country-modal-row
  .col-auto.pr-0
    .d-flex.align-items-stretched.mb-3
      label.settings-label.py-2 {{ $t('countryLabel.first') }}
      om-select#countryOperator.w-10.mx-2(
        size="small"
        :class="$i18n.locale == 'en' ? 'max-width-7' : 'min-width-9--625'"
        :options="createOptionArray(operators, operatorTranslationPrefix)"
        @input="setCountryOperator($event)"
        :value="{ key: currentRule.options.operator, value: getCountryOperatorText(currentRule.options.operator) }"
      )
      label.settings-label.py-2 {{ $t('countryLabel.last') }}
  .col
    .d-flex.align-items-start.flex-wrap.country-modal-overflow-scroll
      om-select#countries.w-12.mb-3(
        size="small"
        optionKey="value"
        optionText="label"
        :options="countryList"
        :hideMultiSelection="true"
        multiple
        searchable
        :value="currentRule.options.countries"
        :placeholder="$t('frontendRules.country.add')"
        ref="multiselect"
      )
    .d-flex.align-items-start.flex-wrap.country-modal-overflow-scroll
      om-chips.mr-2.flex-wrap(
        :items="currentRule.options.countries"
        removable
        contentKey="label"
        :style="'margin-top: -5px'"
      )
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import Tag from '@/components/Elements/Tags/Tag';

  export default {
    components: {
      tag: Tag,
    },
    mixins: [settingsValidation, frontendRuleUtils],

    data() {
      const countryList = require(`@/config/countries_${this.$i18n.locale}.json`);
      countryList.sort((a, b) => {
        const countryA = a.label.toLowerCase();
        const countryB = b.label.toLowerCase();

        if (countryA < countryB) {
          return -1;
        }
        if (countryA > countryB) {
          return 1;
        }
        return 0;
      });

      return {
        operators: ['appear', 'notAppear'],
        countryList,
        operatorTranslationPrefix: 'frontendRules.country.',
      };
    },

    validations: {
      currentRule: {
        options: {
          operator: {
            required,
          },
          countries: {
            required,
          },
        },
      },
    },

    created() {
      this.currentRule.options.operator = this.currentRule.options.operator || 'appear';
    },

    methods: {
      setCountryOperator(event) {
        this.currentRule.options.operator = event.key;
      },
      getCountryOperatorText(text) {
        return this.$t(`frontendRules.country.${text}`);
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../../../sass/variables/variables'
  .max-width
    &-7
      max-width: 7rem
  .min-width
    &-9
      min-width: 9rem
      &--5
        min-width: 9.5rem
      &--625
        min-width: 9.625rem
  [data-modal="new-frontendrules-modal"] .brand-modal-body .multiselect
    max-width: 12.5rem
    margin-bottom: 0.5rem
  .brand-tag
    font-size: 0.8125rem
    color: $om-orange
    height: auto
    padding: 0.6875rem 0.25rem 0.6875rem 0.75rem
    margin-right: 0.625rem
    margin-bottom: 0.5rem
    background: #FFE0CC
    cursor: pointer
  .country-modal-row .form-control
    max-height: 2.5rem
    padding: 0.375rem 0.25rem
    &.settings-label
      font-weight: normal
  .country-modal-row .settings-label
    display: flex
    align-items: center
</style>
<style lang="sass">
  .brand-modal-body .country-modal-row
    .multiselect__tags
      min-height: 2.5rem !important
      border-radius: 4px
      .multiselect__input,
      .multiselect__placeholder
        font-size: .8125rem !important
    .multiselect
      min-height: 2.5rem !important
      max-height: 2.5rem !important
    .multiselect--active
      box-shadow: 0 0 0 0.1875rem rgba(#ed5a29, .25)
      border-radius: 4px !important
      .multiselect__tags
        border: 1px solid #F7B59F !important
        border-radius: 4px !important
        box-shadow: none
    .multiselect__option
      color: #505763
      font-size: 0.8125rem
      white-space: break-spaces
      &--highlight
        background: #F1F2F4
        color: #505763
  .country-modal-overflow-scroll
    margin: -3px
    padding: 3px
</style>
