<template lang="pug">
mixin expression
  .d-flex.align-items-center.mb-3
    om-select.mr-2.px-0.col-2(
      :id="`pageType-${expressionIndex}`"
      :options="createOptionArrayForPageType(pageTypes)"
      :value="{ key: expression.pageType, value: $t(`frontendRules.viewedPage.${expression.pageType}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }) }"
      @input="setPageType($event, expressionGroupIndex, expressionIndex)"
    )

    template(v-if="expression.pageType === HOMEPAGE_TYPE")
      .col.pr-3.mt-1

    template(v-else-if="!hasWhereFilter(expressionGroupIndex, expressionIndex)")
      .col.pr-3.mt-1
        om-link(
          @click="addWhereFilter(expressionGroupIndex, expressionIndex)"
          primary
          withIconLeft
        )
          template(slot="left-icon")
            UilPlusCircle.mr-1(size="20px")
          span.mt-1 {{ $t('frontendRules.viewedPageV2.filter') }}

    template(v-else-if="!loading")
      label.px-0.mr-2.settings-label.w-6 {{ expression.pageType === PAGE_TYPE ? $t('frontendRules.viewedPageV2.whereTheUrl') : $t('where') }}

      om-select.mr-2.px-0.col-2(
        :id="`productOperand-${expressionIndex}`"
        v-if="expression.pageType !== PAGE_TYPE"
        :value="{ key: expression.operand, value: $t(`frontendRules.viewedPage.operands.${expression.operand}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }) }"
        @input="setOperand($event, expressionGroupIndex, expressionIndex)"
        :options="createOptionArrayForOperands(operandsOfCurrentPageType(expressionGroupIndex, expressionIndex))"
      )

      template(v-if="expression.operand === 'productAvailability'")
        om-select.mr-2.px-0.col-1(
          :id="`urlOperators-${expressionIndex}`"
          :class="$i18n.locale === 'hu' ? 'col-2' : 'col-1'"
          :value="{ key: expression.operator, value: $t(expression.operator) }"
          @input="setStringOperatorForSrSf($event, expressionGroupIndex, expressionIndex)"
          :options="createOptionArray(operatorsOfCurrentPageType(expressionGroupIndex, expressionIndex))"
        )

        span.mr-2.settings-label.col {{ $t('available') }}

      template(
        v-else-if="['specificCategory', 'productCategory'].includes(expression.operand) === false"
      )
        om-select.mr-2.px-0.col-2(
          :id="`urlOperators-${expressionIndex}`"
          :value="{ key: expression.operator, value: $t(expression.operator) }"
          @input="setStringOperatorForSrSf($event, expressionGroupIndex, expressionIndex)"
          :options="createOptionArray(operatorsOfCurrentPageType(expressionGroupIndex, expressionIndex))"
        )

      template(v-else-if="['specificCategory', 'productCategory'].includes(expression.operand)")
        span.mr-2.settings-label {{ $t('in') }}

        om-select.px-0.col(
          multiple
          searchable
          :options="categories"
          optionText="key"
          optionKey="value"
          :id="`categories-${expressionIndex}`"
          @input="setCategories($event, expressionGroupIndex, expressionIndex)"
          :value.sync="expressionGroups[expressionGroupIndex][expressionIndex].value"
          :placeholder="$t('select')"
        )

      template(
        v-if="['productAvailability', 'specificCategory', 'productCategory'].includes(expression.operand) === false"
      )
        om-input.px-0.col(
          :id="`urlValue-${expressionIndex}`"
          :error="urlValueError(expressionGroupIndex, expressionIndex)"
          v-model.trim="expressionGroups[expressionGroupIndex][expressionIndex].value"
          :prefix="expression.pageType === PAGE_TYPE && needStartingSlash(expression.operator) ? domainWithDash : null"
          :class="expression.pageType === PAGE_TYPE && needStartingSlash(expression.operator) ? 'url-value-with-prefix' : null"
        )

    .pl-2.d-flex.justify-content-end
      om-link(@click="removeExpression(expressionGroupIndex, expressionIndex)" withIconLeft)
        template(slot="left-icon")
          UilTrashAlt.mr-2(size="20px")

div
  .expression-group-container(
    v-for="(expressionGroup, expressionGroupIndex) in expressionGroups"
    :class="expressionGroupIndex === includeGroupIndex ? 'include' : 'exclude'"
  )
    template(v-if="expressionGroupIndex === includeGroupIndex")
      .title {{ $t('frontendRules.viewedPageV2.include') }}
      .description {{ $t(`frontendRules.viewedPageV2.includeDesc`) }}
    template(v-if="expressionGroupIndex === excludeGroupIndex")
      .title {{ $t('frontendRules.viewedPageV2.exclude') }}
      .description {{ $t(`frontendRules.viewedPageV2.excludeDesc`) }}

    .expressions
      .expression(v-for="(expression, expressionIndex) in expressionGroup")
        +expression

    .new-expression
      om-link(@click="newExpression(expressionGroupIndex)" primary withIconLeft)
        template(slot="left-icon")
          UilPlusCircle.mr-1(size="20px")
        span {{ $t('frontendRules.viewedPageV2.addPages') }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import urlCampaignSettings from '@/mixins/urlCampaignSettings';
  import { getCollections } from '@/services/jfAdapter';
  import { mapGetters } from 'vuex';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import embeddedV3 from '@/mixins/embeddedV3';
  import dynamicContent from '@/mixins/dynamicContent';
  import { JETFABRIC_EMBEDDED_AND_DC } from '@/utils/features';
  import { UilPlusCircle, UilTrashAlt } from '@iconscout/vue-unicons';

  const PAGE_TYPE = 'page';
  const HOMEPAGE_TYPE = 'homepage';
  const INCLUDE_GROUP_INDEX = 0;
  const EXCLUDE_GROUP_INDEX = 1;
  const EDIT_MODES = { CAMPAIGN: 'campaignSettings', SEGMENT: 'segmentSettings' };

  export default {
    components: {
      UilPlusCircle,
      UilTrashAlt,
    },
    mixins: [
      settingsValidation,
      expressionOperations,
      urlCampaignSettings,
      embeddedV3,
      dynamicContent,
      frontendRuleUtils,
    ],
    props: {
      campaign: {
        type: Object,
      },
      editMode: {
        default: EDIT_MODES.CAMPAIGN,
        type: String,
      },
    },
    data: () => ({
      PAGE_TYPE,
      HOMEPAGE_TYPE,
      includeGroupIndex: INCLUDE_GROUP_INDEX,
      excludeGroupIndex: EXCLUDE_GROUP_INDEX,
      basePageTypes: [PAGE_TYPE, HOMEPAGE_TYPE],
      platformBasedPageTypes: ['category', 'product'],
      stringOperators: ['equals', 'contains', 'startsWith', 'endsWith'],
      numberOperators: ['equals', 'lessThan', 'lessThanEquals', 'greaterThan', 'greaterThanEquals'],
      booleanOperators: ['is', 'isNot'],
      regexOperator: 'matchRegex',
      operands: {
        page: [],
        category: ['categoryName', 'numberOfProductsInCategory', 'specificCategory'],
        product: [
          'productName',
          'productCategory',
          'productPrice',
          'productType',
          'productVendor',
          'productAvailability',
          'productTag',
        ],
      },
      categories: [],
      loading: true,
    }),
    computed: {
      ...mapGetters([
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'shopSettingsByDomain',
        'databaseId',
        'getLocale',
        'hasAccountFeature',
      ]),
      expressionGroups() {
        return [
          this.currentRule.options.includeExpressions,
          this.currentRule.options.excludeExpressions,
        ];
      },
      onlyBaseConditions() {
        if (this.editMode === EDIT_MODES.SEGMENT) {
          return false;
        }
        const platformSpecific =
          this.isActiveShopifyDomain(this.domain) || this.isActiveShoprenterDomain(this.domain);
        const isNewCampaignTypes = this.isEmbeddedV3 || this.isDynamicContent;
        const hasJFEmbeddedFlag = this.hasAccountFeature(JETFABRIC_EMBEDDED_AND_DC);

        return (
          platformSpecific === false ||
          (platformSpecific && isNewCampaignTypes && hasJFEmbeddedFlag === false)
        );
      },
      pageTypes() {
        if (!this.onlyBaseConditions) {
          return [...this.basePageTypes, ...this.platformBasedPageTypes];
        }
        return this.basePageTypes;
      },
    },
    watch: {
      'currentRule.options': {
        handler(v) {
          this.handleSavingAccess(v);
        },
        deep: true,
      },
    },
    mounted() {
      this.fetchCategories();
      this.setLoading(false);
      this.handleSavingAccess(this.currentRule.options);
    },
    methods: {
      handleSavingAccess(rule) {
        if (rule.includeExpressions.length || rule.excludeExpressions.length) {
          this.$emit('changeSavingAccess', true);
        } else {
          this.$emit('changeSavingAccess', false);
        }
      },
      setLoading(value) {
        if (!value) {
          this.$nextTick(() => {
            this.loading = value;
          });
        } else {
          this.loading = value;
        }
      },
      newExpression(expressionGroupIndex) {
        this.addExpression(this.groupProperty(expressionGroupIndex), {
          pageType: PAGE_TYPE,
          operator: this.stringOperators[0],
          value: '',
        });
      },
      hasWhereFilter(expressionGroupIndex, expressionIndex) {
        return (
          this.expressionGroups[expressionGroupIndex][expressionIndex].pageType === PAGE_TYPE ||
          this.expressionGroups[expressionGroupIndex][expressionIndex].operand !== null
        );
      },
      addWhereFilter(expressionGroupIndex, expressionIndex) {
        const pageType = this.expressionGroups[expressionGroupIndex][expressionIndex].pageType;
        const expression = {
          pageType,
          operand: this.operands[pageType][0],
          operator: this.stringOperators[0],
          value: '',
        };
        this.replaceExpression(
          this.groupProperty(expressionGroupIndex),
          expressionIndex,
          expression,
        );
      },
      removeExpression(expressionGroupIndex, expressionIndex) {
        this.removeExpressionGroup(this.groupProperty(expressionGroupIndex), expressionIndex);
      },
      urlValueError(expressionGroupIndex, expressionIndex) {
        return this.$v.currentRule.options[this.groupProperty(expressionGroupIndex)].$each[
          expressionIndex
        ].value.$error;
      },
      groupProperty(expressionGroupIndex) {
        return expressionGroupIndex === this.includeGroupIndex
          ? 'includeExpressions'
          : 'excludeExpressions';
      },

      deleteIconVisibilityIfHasWhere(expressionIndex, expressionGroupIndex) {
        return (
          this.hasExpressionWhereConditions(expressionGroupIndex, expressionIndex) &&
          this.expressionGroups[expressionGroupIndex].length === 1 &&
          this.expressionGroups[expressionGroupIndex][expressionIndex].pageType !== PAGE_TYPE
        );
      },
      deleteIconVisibility(ruleGroup, expressionIndex) {
        if (expressionIndex === 0 && ruleGroup.length !== 1) {
          return false;
        }
        return true;
      },
      setPageType(event, expressionGroupIndex, expressionIndex) {
        this.setLoading(true);
        const pageType = event.key;
        const expression = { pageType };

        if (![PAGE_TYPE, HOMEPAGE_TYPE].includes(pageType)) {
          expression.operand = null;
        }
        if (pageType === PAGE_TYPE) {
          expression.operator = this.stringOperators[0];
        }
        if (pageType !== HOMEPAGE_TYPE) {
          expression.value = '';
        }

        this.replaceExpression(
          this.groupProperty(expressionGroupIndex),
          expressionIndex,
          expression,
        );
        this.setLoading(false);
      },
      setStringOperatorForSrSf(event, expressionGroupIndex, expressionIndex) {
        this.setLoading(true);
        const currentExpression = this.expressionGroups[expressionGroupIndex][expressionIndex];
        const expression = { ...currentExpression, operator: event.key };
        this.replaceExpression(
          this.groupProperty(expressionGroupIndex),
          expressionIndex,
          expression,
        );
        this.setLoading(false);
      },
      setCategories(event, expressionGroupIndex, expressionIndex) {
        this.setLoading(true);
        const currentExpression = this.expressionGroups[expressionGroupIndex][expressionIndex];
        const expression = { ...currentExpression, value: event };
        this.replaceExpression(
          this.groupProperty(expressionGroupIndex),
          expressionIndex,
          expression,
        );
        this.setLoading(false);
      },
      setOperand(event, expressionGroupIndex, expressionIndex) {
        this.setLoading(true);
        const currentExpression = this.expressionGroups[expressionGroupIndex][expressionIndex];
        const expression = { pageType: currentExpression.pageType, operand: event.key };

        if (event.key === 'specificCategory') {
          expression.operator = 'equals';
          expression.value = null;
        } else if (event.key === 'productAvailability') {
          expression.operator = 'is';
          expression.value = 'available';
        } else {
          expression.operator = this.stringOperators[0];
          expression.value = '';
        }

        this.replaceExpression(
          this.groupProperty(expressionGroupIndex),
          expressionIndex,
          expression,
        );
        this.setLoading(false);
      },
      createOptionArrayForPageType(optionKeys) {
        return optionKeys.map((element) => ({
          key: element,
          value: this.$t(`frontendRules.viewedPage.${element}`, {
            category: this.isActiveShopifyDomain(this.domain) ? 'collection' : 'category',
          }),
        }));
      },
      createOptionArrayForOperands(optionKeys) {
        return optionKeys.map((element) => ({
          key: element,
          value: this.$t(`frontendRules.viewedPage.operands.${element}`, {
            category: this.isActiveShopifyDomain(this.domain) ? 'collection' : 'category',
          }),
        }));
      },
      hasExpressionWhereConditions(groupIndex, expressionIndex) {
        const expression = this.expressionGroups[groupIndex][expressionIndex];
        if (expression.operand || expression.pageType === PAGE_TYPE) return true;
        return false;
      },
      operandsOfCurrentPageType(groupIndex, index) {
        let operands = this.operands[this.expressionGroups[groupIndex][index].pageType];
        if (this.isActiveShoprenterDomain(this.domain))
          operands = operands.filter((operand) => operand !== 'productType');
        return operands;
      },
      operatorsOfCurrentPageType(groupIndex, index) {
        if (
          ['numberOfProductsInCategory', 'productPrice'].includes(
            this.expressionGroups[groupIndex][index].operand,
          )
        )
          return this.numberOperators;
        if (['productAvailability'].includes(this.expressionGroups[groupIndex][index].operand))
          return this.booleanOperators;
        if (this.expressionGroups[groupIndex][index].pageType === PAGE_TYPE) {
          return [...this.stringOperators, this.regexOperator];
        }
        return this.stringOperators;
      },
      async fetchCategories() {
        const shop = this.shopSettingsByDomain(this.domain);

        if (shop) {
          try {
            const shopId = shop.type === 'shoprenter' ? shop.shopname : shop.myshopify_domain;
            const collections = await getCollections(this.databaseId, shop.type, shopId);

            if (collections) {
              const collectionIdMap = new Map(
                collections.map((collection) => [collection.collectionId, collection]),
              );

              const normalizeName = (collection) => {
                const parentId = collection.parent && collection.parent.collectionId;
                if (parentId && parentId !== collection.collectionId) {
                  const parent = collectionIdMap.get(parentId);

                  if (parent) {
                    return `${normalizeName(parent)} > ${collection.title}`;
                  }
                }

                return collection.title;
              };

              this.categories = collections.map((collection) => ({
                key: normalizeName(collection),
                value: `${collection.collectionId}`,
              }));
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          this.categories = [];
        }
      },
    },
    validations: {
      currentRule: {
        options: {
          includeExpressions: {
            $each: {
              value: {
                isCool(v, { operator, pageType }) {
                  return pageType !== PAGE_TYPE || this.needStartingSlash(operator) || v.length;
                },
              },
            },
          },
          excludeExpressions: {
            $each: {
              value: {
                isCool(v, { operator, pageType }) {
                  return pageType !== PAGE_TYPE || this.needStartingSlash(operator) || v.length;
                },
              },
            },
          },
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'

  .expression-group-container.include
    margin-bottom: 30px

  .expression-group-container
    background-color: $om-gray-100
    border: 1px solid $om-gray-300
    padding: 1rem
    border-radius: 4px

    .title, .description
      padding: 0
      margin: 0

    .title
      font-weight: 600
      font-size: 120%
      margin-bottom: 0.5rem

    .description
      margin-bottom: 1rem

    .new-expression
      margin-top: 1.5rem
      margin-bottom: 0.5rem
      width: fit-content

    .delete-operator
      width: fit-content
</style>

<style lang="sass">
  .url-value-with-prefix
    .input-element
      padding-left: 0 !important
      padding: 0 !important
</style>
