<template lang="pug">
div
  template(v-for="(expression, index) in currentRule.options.expression")
    device-chooser.mb-4(:currentRule.sync="expression")
    .form-group.row.mb-4
      .col-12.d-flex.align-items-center
        om-input(
          :style="'flex: 1 1 100%;'"
          :label="$t('jsEvent')"
          :id="`eventTrigger-${index}`"
          type="text"
          sideLabel="auto"
          :error="$v.currentRule.options.expression.$each[index].eventName.$error"
          :value="currentRule.options.expression[index].eventName"
          @change.native="stripTagsFromSelector($event, index)"
        )
        i.ml-3.fas.fa-times.cursor-pointer(
          v-if="Object.keys(currentRule.options.expression).length > 1"
          @click="removeExpression(expression.eventName)"
        )
        span.ml-2(v-else)
  span.brand-link.mt-4.settings-label(@click="addExpression") {{ $t('addNew') }}
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';
  import { stripTags } from '@/util';
  import DeviceChooser from './DeviceChooser.vue';

  export default {
    components: { DeviceChooser },
    mixins: [settingsValidation],

    validations: {
      currentRule: {
        options: {
          expression: {
            $each: {
              eventName: {
                required,
              },
            },
          },
        },
      },
    },

    methods: {
      removeExpression(eventName) {
        const index = this.currentRule.options.expression.findIndex(
          (e) => e.eventName === eventName,
        );
        this.currentRule.options.expression.splice(index, 1);
      },
      addExpression() {
        this.currentRule.options.expression.push({ eventName: '', device: 'desktop_and_mobile' });
      },
      stripTagsFromSelector(event, index) {
        this.$set(this.currentRule.options.expression[index], 'eventName', '');
        const cleanStr = stripTags(event.target.value);
        this.$set(this.currentRule.options.expression[index], 'eventName', cleanStr);
      },
    },
  };
</script>
