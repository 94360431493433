<template lang="pug">
div
  template(v-for="expressionGroup in groupValuesByFor(currentRule.options.expressions)")
    .row.cart-rule-row.align-items-start.mt-1.mb-3.pb-1.mx-0.px-2
      .col-auto.align-self-start.py-2.line-height-1-25
        label.settings-label(
          v-if="isFirstRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex)"
        ) {{ expressionGroup.exprIndex ? `${$t('and')}` : `${$t('visitorCartRevamp.appearIf')}` }}
      .align-self-start.col-auto
        om-select(
          :style="locale === 'en' ? 'width: 15.625rem' : 'width: 21.625rem'"
          v-if="isFirstRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex)"
          :id="`visitorCartRule-${expressionGroup.exprIndex}`"
          size="small"
          @input="setVisitorCartRule($event, expressionGroup.groupIndex, expressionGroup.exprIndex)"
          :value="{ key: currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].for, value: getVisitorCartRule(currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].for) }"
          :options="visitorCartOptions(reducedRuleTypes(expressionGroup.groupIndex, expressionGroup.exprIndex), 'rules')"
        )
      .d-flex.align-self-start(
        :class="tplMiddleClasses(expressionGroup.groupIndex, expressionGroup.exprIndex)"
      )
        om-select.mr-3(
          :id="`onlyNumberOperators-${expressionGroup.exprIndex}`"
          size="small"
          :style="locale === 'en' ? 'max-width: 12.25rem' : 'max-width: 13.625rem'"
          :options="visitorCartOptions(onlyNumberOperators, 'operators')"
          @input="setVisitorCartOperator($event, expressionGroup.groupIndex, expressionGroup.exprIndex)"
          v-if="isCartRestrictions(expressionGroup.groupIndex, expressionGroup.exprIndex) && isFirstRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex)"
          :error="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex, 'operator')"
          :value="{ key: currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].operator || 'greaterThan', value: getOperatorText(currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].operator || 'greaterThan') }"
        )
        span.mr-3.py-2.settings-label(
          v-if="isShopifyOrShoprenter && !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_') && locale === 'en'"
        ) {{ $t('visitorCartRevamp.inTheCart') }}
        .d-flex.align-items-center.flex-wrap
          template(
            v-if="!isShopifyOrShoprenter && !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_') && isFirstRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex)"
          )
            .col-auto
              span.settings-label {{ $t('visitorCartRevamp.haveAProperty') }}
            .col-auto
              om-input(
                :placeholder="$t('visitorCartRevamp.placeholders.property')"
                :id="`attributeName-${expressionGroup.exprIndex}`"
                style="max-width: 9.5rem"
                type="text"
                small
                v-model.lazy="currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName"
                @input="updatePropsInRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex, [{ key: 'attributeName', value: $event }])"
                :errorText="$t('visitorCartRevamp.errors.selectOrDelete')"
                :error="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex, 'attributeName') && !isShopifyOrShoprenter"
              )
            .col-auto
              span.settings-label {{ $t('visitorCartRevamp.whichIs') }}
            .col-auto
              om-select.mr-2(
                size="small"
                :id="`attributeType-${expressionGroup.exprIndex}`"
                :options="attributeTypes"
                style="width: 6rem"
                @input="setAttributeType($event, expressionGroup.groupIndex, expressionGroup.exprIndex)"
                :value="{ key: currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeType, value: $t(`${currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeType}`) }"
              )
          .row.col-12.align-items-start(
            :class="{ 'mb-2': !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_'), 'pb-1': !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_'), 'mx-0': !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_') } && { 'mt-2 pt-1 mx-0 px-0': !isShopifyOrShoprenter && !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_') }"
          )
            template(
              v-if="!isShopifyOrShoprenter && !currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].attributeName.startsWith('_') && isFirstRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex)"
            )
              .col-auto.my-2.py-1(v-if="locale === 'en'")
                span.settings-label {{ $t('visitorCartRevamp.thatIs') }}
              .col-auto.mb-2.pb-1
                om-select(
                  :id="`variableOperators-${expressionGroup.exprIndex}`"
                  :style="locale === 'en' ? 'width: 10rem' : 'width: 12rem'"
                  size="small"
                  :options="visitorCartOptions(variableOperators(expressionGroup.groupIndex, expressionGroup.exprIndex), 'operators')"
                  @input="setVariableOperator($event, expressionGroup.groupIndex, expressionGroup.exprIndex)"
                  :value="{ key: currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].operator, value: getOperatorText(currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex].operator) }"
                )
            .col-auto.mb-2.pb-1(
              v-for="(value, exprValueIndex) in expressionGroup.values"
              v-if="!isExistOperator(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
              :class="(getValueClasses(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex), { 'd-flex': !isShopifyOrShoprenter })"
            )
              .d-flex.align-items-start.flex-wrap(:class="{ 'ml-1': locale !== 'en' }")
                template(
                  v-if="currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex + exprValueIndex].operator === 'interval'"
                )
                  .d-flex.align-items-center
                    om-input(
                      :id="`intervalValue1-${expressionGroup.exprIndex + exprValueIndex}`"
                      type="text"
                      small
                      style="max-width: 6.25rem"
                      :value="getIntervalValue(value, 0)"
                      @input="setIntervalValue(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, $event, 0)"
                      :placeholder="$t('visitorCartRevamp.placeholders.value50')"
                      :error="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, 'value')"
                    )
                    span.mx-2.px-1.font-size-0--8125.font-weight-500 {{ $t('and') }}
                    om-input(
                      :id="`intervalValue2-${expressionGroup.exprIndex + exprValueIndex}`"
                      type="text"
                      small
                      style="max-width: 6.25rem"
                      :value="getIntervalValue(value, 1)"
                      @input="setIntervalValue(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, $event, 1)"
                      :placeholder="$t('visitorCartRevamp.placeholders.value100')"
                      :error="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, 'value')"
                    )
                  .form-text.text-danger(
                    v-if="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, 'value') && !isShopifyOrShoprenter && currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex + exprValueIndex].operator === 'interval'"
                  )
                    small.d-flex.py-0.alert-red-500 {{ $t(getErrorMessage(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)) }}
                template(v-else)
                  .d-flex.flex-column.px-0(
                    v-if="canShowValueInput(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
                  )
                    .d-flex
                      om-input(
                        :id="`value-${expressionGroup.exprIndex + exprValueIndex}`"
                        style="width: 6.25rem"
                        v-if="canShowValueInput(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
                        type="text"
                        :value="value"
                        small
                        :placeholder="getPlaceholder(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
                        @input="setExpressionValue(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, $event)"
                        :error="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, 'value')"
                      )
                      span.brand-link.settings-label.ml-2.mr-1.font-size-0--875.py-2(
                        v-if="isMultipleValuesInRuleGroup(expressionGroup.groupIndex, currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex + exprValueIndex].for)"
                        @click="removeExpressionFromGroup('expressions', expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
                      )
                        fa-icon(variant="fa-trash" size="1.5")

                    .form-text.text-danger(
                      v-if="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, 'value') && !isShopifyOrShoprenter"
                    )
                      small.d-flex.py-0.alert-red-500 {{ $t(getErrorMessage(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)) }}
                template(
                  v-if="!isCartRestrictions(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex) && isShopifyOrShoprenter"
                )
                  om-chips(
                    :items="currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex + exprValueIndex].value"
                    removable
                    contentKey="title"
                    v-if="currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex + exprValueIndex].value"
                    :style="'margin-top: -5px'"
                  )
                span.d-flex.brand-link.settings-label.font-size-0--875.py-2(
                  v-if="exprValueIndex === expressionGroup.values.length - 1 && !isCartRestrictions(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex) && hasMultipleOptions(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
                  @click="addItem('expressions', expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)"
                  :class="{ 'mb-2': currentRule.options.expressions[expressionGroup.groupIndex][expressionGroup.exprIndex + exprValueIndex].value.length } && { 'ml-3 line-height-1-5': !isShopifyOrShoprenter }"
                )
                  fa-icon.line-height-1(variant="fa-plus-circle" size="1.5")
                  span.ml-2(
                    v-html="`${isShopifyOrShoprenter ? $t('selectItems') : $t('addItem')}`"
                  )
                .form-text.text-danger(
                  v-if="isExpressionValueInvalid(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex, 'value') && isShopifyOrShoprenter"
                )
                  small.d-flex.py-0.alert-red-500 {{ $t(getErrorMessage(expressionGroup.groupIndex, expressionGroup.exprIndex + exprValueIndex)) }}
      .py-2(
        style="max-width: 1.5rem"
        :class="((isLastRuleGroupGroup(expressionGroup.groupIndex, expressionGroup.exprIndex) || availableRuleTypes.length) && !isCartRestrictions(expressionGroup.groupIndex, expressionGroup.exprIndex) && isShopifyOrShoprenter) || !isShopifyOrShoprenter ? 'col-auto' : 'col'"
      )
        .d-flex.align-items-center.justify-content-end
          template(v-if="isFirstRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex)")
            span.brand-link.settings-label.ml-2.font-size-0--875(
              v-if="usedRuleTypes.length > 1"
              @click="removeAllExpressions(expressionGroup.groupIndex, getPropFromRuleGroup(expressionGroup.groupIndex, expressionGroup.exprIndex, 'for'))"
            )
              fa-icon(variant="fa-trash" size="1.5")
  .col-12.px-0(v-if="availableRuleTypes.length")
    .d-flex.align-items-center.justify-content-end(style="max-width: 14.25rem")
      span.d-flex.brand-link.settings-label.font-size-0--875(
        @click="addExpressionToGroup('expressions', 0, nextRemainingOption())"
      )
        fa-icon(variant="fa-plus-circle" size="1.5")
        span.ml-2 {{ $t('visitorCartRevamp.addRule') }}
</template>
<script>
  import { required } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import attributeTypes from '@/mixins/attributeTypes';
  import Tag from '@/components/Elements/Tags/Tag';
  import { CART_RULES_SHOP_TYPE_EXPRESSION } from '@/utils/constant';
  import {
    stringOperators,
    numberOperators,
    onlyNumberOperators,
    existsOperators,
  } from './statics';

  const DEFAULT_ATTRIBUTE_NAMES = {
    cartValue: '_cart_total',
    totalNumberOfProducts: '_number_of_cart_items',
    numberOfDiffProducts: '_number_of_cart_item_kinds',
  };

  export default {
    components: {
      tag: Tag,
    },
    mixins: [settingsValidation, expressionOperations, attributeTypes],
    props: {
      isShopify: {
        type: Boolean,
      },
      isShoprenter: {
        type: Boolean,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      locale: {
        type: String,
      },
    },
    data: () => ({
      stringOperators,
      numberOperators,
      onlyNumberOperators,
      existsOperators,
      numberRuleTypes: ['cartValue', 'totalNumberOfProducts', 'numberOfDiffProducts'],
      itemRuleTypes: ['atLeastOne', 'allItems', 'none'],
      productRuleTypes: CART_RULES_SHOP_TYPE_EXPRESSION,
    }),
    computed: {
      isShopifyOrShoprenter() {
        return this.isShopify || this.isShoprenter;
      },
      cartRuleTypes() {
        if (this.isShopifyOrShoprenter) {
          return [
            'cartValue',
            'totalNumberOfProducts',
            'numberOfDiffProducts',
            'atLeastOneProducts',
            'allProducts',
            'noneOfProducts',
          ];
        }
        return [
          'cartValue',
          'totalNumberOfProducts',
          'numberOfDiffProducts',
          'atLeastOne',
          'allItems',
          'none',
        ];
      },
      usedRuleTypes() {
        const used = new Set();
        this.currentRule.options.expressions.forEach((expressionGroup) => {
          expressionGroup.forEach((expression) => {
            used.add(expression.for);
          });
        });
        return Array.from(used);
      },
      availableRuleTypes() {
        return this.cartRuleTypes.filter((ruleType) => !this.usedRuleTypes.includes(ruleType));
      },
    },
    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              $each: {
                operator: { required },
                attributeName: {
                  isReq(val) {
                    return this.isShopifyOrShoprenter ? true : val !== '';
                  },
                },
                value: {
                  isReq(val, expression) {
                    if (
                      expression.for === 'cart' &&
                      this.currentRule.options.expressions.length > 1
                    ) {
                      return true;
                    }
                    if (Array.isArray(val)) {
                      return !!val.length;
                    }
                    return !this.needsInput(expression.operator) || val !== '';
                  },
                  isCool(val, expression) {
                    if (!this.needsInput(expression.operator)) return true;
                    if (expression.operator === 'interval') {
                      const parts = `${val}`.replace(';', '-').split('-');

                      if (parts[0] === '' || parts[1] === '') return false;

                      return /^-?[0-9]*$/.test(parts[0]) && /^-?[0-9]*$/.test(parts[1]);
                    }
                    return expression.attributeType === 'number' ? /^-?[0-9]*$/.test(val) : true;
                  },
                },
              },
            },
          },
        },
      },
    },
    methods: {
      visitorCartOptions(optionKeys, type) {
        return optionKeys.map((element) => ({
          key: element,
          value: this.$t(`visitorCartRevamp.${type}.${element}`),
        }));
      },
      getVisitorCartRule(key) {
        return this.$t(`visitorCartRevamp.rules.${key}`);
      },
      getOperatorText(key) {
        return this.$t(`visitorCartRevamp.operators.${key}`);
      },
      setAttributeType(event, groupIndex, exprIndex) {
        this.onTypeChange(groupIndex, exprIndex, event.key);
        this.currentRule.options.expressions[groupIndex][exprIndex].attributeType = event.key;
      },
      setVisitorCartRule(event, groupIndex, exprIndex) {
        if (this.currentRule.options.expressions[groupIndex][exprIndex].for !== event.key) {
          this.setToDefaults(groupIndex, exprIndex, event.key);
        }
        this.currentRule.options.expressions[groupIndex][exprIndex].for = event.key;
      },
      setVisitorCartOperator(event, groupIndex, exprIndex) {
        this.onFirstOperatorChange(groupIndex, exprIndex, event.key);
        this.currentRule.options.expressions[groupIndex][exprIndex].operator = event.key;
      },
      setVariableOperator(event, groupIndex, exprIndex) {
        this.onOperatorChange(groupIndex, exprIndex, event.key);
        this.currentRule.options.expressions[groupIndex][exprIndex].operator = event.key;
      },
      keepSelectedProducts(oldRuleType, newRuleType) {
        return (
          this.isShopifyOrShoprenter &&
          !this.numberRuleTypes.includes(oldRuleType) &&
          !this.numberRuleTypes.includes(newRuleType)
        );
      },
      setToDefaults(groupIndex, exprIndex, newRuleType) {
        this.$v.$reset();

        const oldRuleType = this.getPropFromRuleGroup(groupIndex, exprIndex, 'for');
        const currentValue = this.currentRule.options.expressions;
        const oldValue = currentValue[groupIndex][exprIndex].value;
        currentValue[groupIndex][exprIndex].for = newRuleType;

        this.removeAllExpressions(groupIndex, oldRuleType);
        const currentAttributeName = DEFAULT_ATTRIBUTE_NAMES[newRuleType] || '';

        const isCartRestrictions = this.isCartRestrictions(groupIndex, exprIndex);

        this.$nextTick(() => {
          currentValue[groupIndex][exprIndex].attributeName = currentAttributeName;
          currentValue[groupIndex][exprIndex].operator = isCartRestrictions
            ? 'greaterThan'
            : 'contains';
          currentValue[groupIndex][exprIndex].value = this.keepSelectedProducts(
            oldRuleType,
            newRuleType,
          )
            ? oldValue
            : '';
          currentValue[groupIndex][exprIndex].attributeType = isCartRestrictions
            ? 'number'
            : 'string';
        });
      },
      getIntervalValue(value, index) {
        const values = `${value}`.split('-');
        if (values.length === 2) return values[index];
        return index === 0 ? values[0] : '';
      },
      setIntervalValue(groupIndex, exprIndex, value, index) {
        const currentValue =
          this.currentRule.options.expressions[groupIndex][exprIndex].value || '-';
        const parts = currentValue.split('-');
        parts[index] = value;
        this.currentRule.options.expressions[groupIndex][exprIndex].value = parts.join('-');
      },
      currentOperator(groupIndex, index) {
        return this.currentRule.options.expressions[groupIndex][index].operator;
      },
      typeBasedOperators(groupIndex, index) {
        return this.currentRule.options.expressions[groupIndex][index].attributeType === 'string'
          ? stringOperators
          : numberOperators;
      },
      needsInput(operator) {
        return !existsOperators.includes(operator);
      },
      onOperatorChange(groupIndex, exprIndex, newOperator) {
        const oldOperator = this.currentRule.options.expressions[groupIndex][exprIndex].operator;
        const value = this.currentRule.options.expressions[groupIndex][exprIndex].value;
        if (oldOperator === 'interval') {
          this.splitIntervalValue(groupIndex, exprIndex);
        }
        if (newOperator === 'interval') {
          this.setIntervalValue(groupIndex, exprIndex, value, 0);
        }
        this.currentRule.options.expressions[groupIndex][exprIndex].operator = newOperator;
        const props = ['operator'];
        if (!this.needsInput(this.getPropFromRuleGroup(groupIndex, exprIndex, 'operator'))) {
          props.push({ key: 'value', value: '' });
          const ruleType = this.getPropFromRuleGroup(groupIndex, exprIndex, 'for');
          const lastIndex = this.getLastIndexOfRuleGroup(groupIndex, ruleType);
          if (lastIndex - exprIndex > 0) {
            this.currentRule.options.expressions[groupIndex].splice(
              exprIndex + 1,
              lastIndex - exprIndex,
            );
          }
        }
        this.updatePropsInRuleGroup(groupIndex, exprIndex, props);
        this.removeExtraFields(groupIndex, exprIndex);
      },
      isCartRestrictions(groupIndex, exprIndex) {
        return ['cartValue', 'totalNumberOfProducts', 'numberOfDiffProducts'].includes(
          this.currentRule.options.expressions[groupIndex][exprIndex].for,
        );
      },
      isExistOperator(groupIndex, exprIndex) {
        return this.existsOperators.includes(
          this.currentRule.options.expressions[groupIndex][exprIndex].operator,
        );
      },
      getFirstIndexOfRuleGroup(groupIndex, ruleType) {
        return this.currentRule.options.expressions[groupIndex].findIndex(
          ({ for: type }) => ruleType === type,
        );
      },
      isFirstRuleGroup(groupIndex, exprIndex) {
        const currentFor = this.currentRule.options.expressions[groupIndex][exprIndex].for;
        const forIndex = this.getFirstIndexOfRuleGroup(groupIndex, currentFor);

        return forIndex === exprIndex;
      },
      getLastIndexOfRuleGroup(groupIndex, ruleType) {
        let lastIndex;
        this.currentRule.options.expressions[groupIndex].forEach(({ for: type }, index) => {
          if (type === ruleType) {
            lastIndex = index;
          }
        });
        return lastIndex;
      },
      isLastRuleGroup(groupIndex, exprIndex) {
        const currentFor = this.currentRule.options.expressions[groupIndex][exprIndex].for;
        const forIndex = this.getLastIndexOfRuleGroup(groupIndex, currentFor);
        return forIndex === exprIndex;
      },
      isLastRuleGroupGroup(groupIndex, exprIndex) {
        const group = this.currentRule.options.expressions[groupIndex];
        const lastFor = group[group.length - 1].for;
        const currentFor = this.currentRule.options.expressions[groupIndex][exprIndex].for;

        return lastFor === currentFor;
      },
      updatePropsInRuleGroup(groupIndex, exprIndex, props) {
        const rule = this.currentRule.options.expressions[groupIndex][exprIndex];
        this.currentRule.options.expressions[groupIndex].forEach((expression, index) => {
          if (rule.for === expression.for) {
            props.forEach((prop) => {
              if (typeof prop === 'object') {
                this.currentRule.options.expressions[groupIndex][index][prop.key] = prop.value;
              } else {
                this.currentRule.options.expressions[groupIndex][index][prop] = rule[prop];
              }
            });
          }
        });
      },
      getPropFromRuleGroup(groupIndex, exprIndex, prop) {
        const group = this.currentRule.options.expressions[groupIndex];
        const ruleType = group[exprIndex].for;
        const firstRule = group.find(({ for: type }) => ruleType === type);

        return firstRule[prop];
      },
      nextRemainingOption() {
        const forValue = this.availableRuleTypes[0];
        const isNumberType = this.numberRuleTypes.includes(forValue);
        const attributeName = DEFAULT_ATTRIBUTE_NAMES[forValue] || '';
        const attributeType = isNumberType ? 'number' : 'string';
        const operator = isNumberType ? 'greaterThan' : 'equals';
        return { for: forValue, attributeName, attributeType, operator, value: '', type: 'string' };
      },
      addExpressionToGroupInRuleType(fieldName, groupIndex, exprIndex) {
        const current = this.currentRule.options[fieldName][groupIndex][exprIndex];
        const newRule = { ...current, value: '' };
        const lastIndex = this.getLastIndexOfRuleGroup(groupIndex, current.for);
        this.currentRule.options[fieldName][groupIndex].splice(lastIndex + 1, 0, newRule);
      },
      reducedRuleTypes(groupIndex, exprIndex) {
        const current = this.currentRule.options.expressions[groupIndex][exprIndex].for;
        const hiddenOptions = this.usedRuleTypes.filter((ruleType) => ruleType !== current);
        return this.cartRuleTypes.filter((ruleType) => !hiddenOptions.includes(ruleType));
      },
      removeAllExpressions(groupIndex, type) {
        const firstIndex = this.getFirstIndexOfRuleGroup(groupIndex, type);
        const lastIndex = this.getLastIndexOfRuleGroup(groupIndex, type);
        const count = lastIndex - firstIndex + 1;
        // eslint-disable-next-line
        const removed = this.currentRule.options.expressions[groupIndex].splice(firstIndex, count);
      },
      tplMiddleClasses(groupIndex, exprIndex) {
        return {
          col: !this.isShopifyOrShoprenter || this.isCartRestrictions(groupIndex, exprIndex),
          'line-height-1-25': !this.isShopifyOrShoprenter,
          'line-height-1-7 col':
            this.isShopifyOrShoprenter && !this.isCartRestrictions(groupIndex, exprIndex),
        };
      },
      getValueClasses(groupIndex, exprIndex) {
        const isInvalid = this.isExpressionValueInvalid(groupIndex, exprIndex, 'value');
        return {
          alert: isInvalid,
          'pt-0': isInvalid,
        };
      },
      isExpressionValueInvalid(groupIndex, exprIndex, prop) {
        return this.$v.currentRule.options.expressions.$each[groupIndex].$each[exprIndex][prop]
          .$error;
      },
      isMultipleValuesInRuleGroup(groupIndex, ruleType) {
        const ruleGroup = this.currentRule.options.expressions[groupIndex].filter(
          (expression) => expression.for === ruleType,
        );
        return ruleGroup.length > 1;
      },
      canShowValueInput(groupIndex, exprIndex) {
        return !this.isShopifyOrShoprenter || this.isCartRestrictions(groupIndex, exprIndex);
      },
      addItem(fieldName, groupIndex, exprIndex) {
        if (this.isShopifyOrShoprenter) {
          this.$bus.$emit('openCatalog', {
            currentRules: this.currentRule,
            groupIndex,
            exprIndex,
            isEditMode: this.isEditMode,
          });
        } else {
          this.addExpressionToGroupInRuleType(fieldName, groupIndex, exprIndex);
        }
      },
      variableOperators(groupIndex, exprIndex) {
        const { attributeType } = this.currentRule.options.expressions[groupIndex][exprIndex];
        return attributeType === 'string' ? this.stringOperators : this.numberOperators;
      },
      onTypeChange(groupIndex, exprIndex, type) {
        const defaultOperator = type === 'string' ? 'contains' : 'equals';

        this.$nextTick(() => {
          this.currentRule.options.expressions[groupIndex][exprIndex].operator = defaultOperator;
        });
        this.removeExtraFields(groupIndex, exprIndex);
        this.updatePropsInRuleGroup(groupIndex, exprIndex, [{ key: 'attributeType', value: type }]);
      },
      hasMultipleOptions(groupIndex, exprIndex) {
        const firstIndexOfRuleGroup = this.getFirstIndexOfRuleGroup(
          groupIndex,
          this.currentRule.options.expressions[groupIndex][exprIndex].for,
        );
        const type =
          this.currentRule.options.expressions[groupIndex][firstIndexOfRuleGroup].attributeType;
        const operator =
          this.currentRule.options.expressions[groupIndex][firstIndexOfRuleGroup].operator;
        return type === 'string' || (type === 'number' && operator === 'equals');
      },
      removeExtraFields(groupIndex, exprIndex) {
        const operator = this.currentRule.options.expressions[groupIndex][exprIndex].operator;
        const type = this.currentRule.options.expressions[groupIndex][exprIndex].attributeType;
        if (type === 'number' && operator !== 'equals') {
          const lastIndex = this.getLastIndexOfRuleGroup(
            groupIndex,
            this.currentRule.options.expressions[groupIndex][exprIndex].for,
          );
          if (lastIndex > 0) {
            this.currentRule.options.expressions[groupIndex].splice(
              exprIndex + 1,
              lastIndex - exprIndex,
            );
          }
        }
      },
      splitIntervalValue(groupIndex, exprIndex) {
        const value = this.currentRule.options.expressions[groupIndex][exprIndex].value;
        const parts = value.split('-');
        this.currentRule.options.expressions[groupIndex][exprIndex].value = parts[0];
      },
      onFirstOperatorChange(groupIndex, exprIndex, newOperator) {
        const oldOperator = this.currentRule.options.expressions[groupIndex][exprIndex].operator;
        const value = this.currentRule.options.expressions[groupIndex][exprIndex].value;
        if (oldOperator === 'interval') {
          this.splitIntervalValue(groupIndex, exprIndex);
        }
        if (newOperator === 'interval') {
          this.setIntervalValue(groupIndex, exprIndex, value, 0);
        }
        this.currentRule.options.expressions[groupIndex][exprIndex].operator = newOperator;
      },
      getPlaceholder(groupIndex, exprIndex) {
        if (this.isCartRestrictions(groupIndex, exprIndex)) {
          return this.$t('visitorCartRevamp.placeholders.value50');
        }

        if (
          this.currentRule.options.expressions[groupIndex][exprIndex].attributeType === 'number'
        ) {
          return this.$t('visitorCartRevamp.placeholders.number');
        }

        if (
          this.currentRule.options.expressions[groupIndex][exprIndex].attributeType === 'string'
        ) {
          return this.$t('visitorCartRevamp.placeholders.value');
        }
      },
      setExpressionValue(groupIndex, exprIndex, value) {
        this.currentRule.options.expressions[groupIndex][exprIndex].value = value || '';
      },
      groupValuesByFor(expressionGroups) {
        const prop = 'for';
        const result = expressionGroups.map((expressionGroup, groupIndex) => {
          return expressionGroup.reduce((acc, expression, exprIndex) => {
            const existingExpressionIndex = acc.findIndex(
              (item) => item && item[prop] === expression[prop],
            );
            if (existingExpressionIndex > -1) {
              acc[existingExpressionIndex].values.push(expression.value);
            } else {
              acc.push({ ...expression, exprIndex, groupIndex, values: [expression.value] });
            }
            return acc;
          }, []);
        });
        return result.flat();
      },
      getErrorMessage(groupIndex, exprIndex) {
        const value = this.currentRule.options.expressions[groupIndex][exprIndex].value;
        if (this.currentRule.options.expressions[groupIndex][exprIndex].operator === 'interval') {
          const parts = `${value}`.replace(';', '-').split('-');
          if (parts[0] === '' || parts[1] === '') return 'visitorCartRevamp.errors.selectOrDelete';
        }

        if (value === '') return 'visitorCartRevamp.errors.selectOrDelete';

        return 'visitorCartRevamp.errors.invalidValue';
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../../../sass/variables/variables'
  [data-modal="new-frontendrules-modal"] .form-control.is-invalid
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='20' height='20' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
    background-repeat: no-repeat
    background-color: white
    border-color: $om-alert-red-500
    background-position: right .5rem center
    background-size: calc(.75rem + .5rem) calc(.75rem + .5rem)
    &:hover:not(:disabled):not(:focus)
      border-color: $om-alert-red-500
  .brand-tag
    font-size: 0.8125rem
    color: $om-orange
    height: auto
    padding: 10px 4px 10px 12px
    margin-right: 10px
    margin-bottom: 8px
    background: #FFE0CC
    cursor: pointer
  .cart-rule-row
    margin-left: -6px
    margin-right: -6px
    [class*="col"]
      padding-left: 6px
      padding-right: 6px
</style>
<style lang="sass">
  .v--modal-overlay
    &[data-modal="new-frontendrules-modal"]
      .form-text.text-danger
        margin-top: 4px
        flex: 0 0 100%
        max-width: 100%
        white-space: nowrap
      .form-text.text-danger
        .form-control
          margin-top: .75rem
  .brand-tag-remove
    margin-left: 6px
    i
      color: #F7B59F
</style>
