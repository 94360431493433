<template lang="pug">
.d-flex.align-items-center
  .col-5
    om-select#minimumPageVisitOperator(
      :options="minimumPageVisitOperators"
      :label="$t('numberOfPages')"
      :value="minimumPageVisit"
      labelPosition="fill"
      @input="setMinimumPageVisitOperator($event)"
    )
  om-input#minimumPageVisit.w-4(
    type="number"
    :min="minimumValue"
    :error="$v.currentRule.options.value.$error"
    v-model.number="currentRule.options.value"
  )
</template>

<script>
  import { required, integer } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';

  const MINIMUM_VALUE_FOR_MINIMUM_OPERATOR = 2;
  const MINIMUM_VALUE_FOR_MAXIMUM_OPERATOR = 1;

  const validateValue = function (value) {
    return this.minimumValue <= value;
  };

  export default {
    mixins: [settingsValidation],
    data() {
      return {
        minimumPageVisitOperators: [
          { key: 'atLeast', value: this.$t('frontendRules.minimumPageVisit.operators.atLeast') },
          { key: 'maximum', value: this.$t('frontendRules.minimumPageVisit.operators.maximum') },
        ],
      };
    },
    computed: {
      minimumPageVisit() {
        return {
          key: this.currentRule.options.operator,
          value: this.$t(
            `frontendRules.minimumPageVisit.operators.${this.currentRule.options.operator}`,
          ),
        };
      },
      minimumValue() {
        return this.currentRule.options.operator === 'maximum'
          ? MINIMUM_VALUE_FOR_MAXIMUM_OPERATOR
          : MINIMUM_VALUE_FOR_MINIMUM_OPERATOR;
      },
    },

    watch: {
      'currentRule.options.operator': function (newValue) {
        if (
          newValue === 'atLeast' &&
          this.currentRule.options.value < MINIMUM_VALUE_FOR_MINIMUM_OPERATOR
        ) {
          this.currentRule.options.value = MINIMUM_VALUE_FOR_MINIMUM_OPERATOR;
        }
      },
    },
    methods: {
      setMinimumPageVisitOperator(event) {
        this.currentRule.options.operator = event.key;
      },
    },
    validations: {
      currentRule: {
        options: {
          operator: {
            required,
          },
          value: {
            required,
            minValue: validateValue,
            integer,
          },
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  .was-validated .form-control:invalid, .form-control.is-invalid
    padding-right: 0
</style>
